<template>
  <div>
    <div class="content-tab border-top-line mb-5">
      <v-container
        fluid
        style="width: 100%"
      >
        <v-row v-if="loaderSkeleton">
          <v-col cols="12">
            <div>
              <v-sheet
                color="grey lighten-4"
                class="pa-0"
                height="450px"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="table"
                ></v-skeleton-loader>
              </v-sheet>
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col
            class="pa-0"
            cols="12"
            sm="12"
          >
            <div class="width-100">
              <div v-if="aDisputes.length > 0">
                <div v-if="isResponsive">
                  <div
                    v-for="(item, index) in aDisputes"
                    :key="index"
                    class="card-content mb-5"
                  >
                    <p
                      style="font-size: 25px;"
                      class="mon-bold text-left"
                    >{{ item.oSalesOrder.sFolioId }}</p>
                    <div class="display-flex align-items-center">
                      <p class="mon-bold">{{ texts.disputes.tableFinalized[1].text }}</p>
                      <v-spacer />
                      <p class="mon-regular text-left">{{ item.oPurchaseOrder.sFolioId }}</p>
                    </div>
                    <div class="display-flex align-items-center">
                      <p class="mon-bold">{{ texts.disputes.tableFinalized[2].text }}</p>
                      <v-spacer />
                      <p class="mon-regular text-left">{{ item.oVendor.sName }}</p>
                    </div>
                    <div>
                      <v-btn
                        class="button-primary mon-bold mt-5 mb-2"
                        style="width: 100%;"
                        @click="redirecDisputeDetail(item.sClaimTicketId)"
                        elevation="0"
                      >
                        {{ selectLanguage == 'sp' ? 'Ver detalle' : 'View detail' }}
                      </v-btn>
                    </div>
                  </div>
                  <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                    <div class="pagination-styles">
                      <v-pagination
                        v-model="iCurrentPage"
                        :length="lengthPage"
                        color="#FFC556"
                        next-icon="mdi-menu-right"
                        prev-icon="mdi-menu-left"
                      ></v-pagination>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="card-content"
                >
                  <div>
                    <v-simple-table height="450px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              v-for="(item, index) in texts.disputes.tableFinalized"
                              :key="index"
                              :class="`${item.align} text-header-title mon-bold`"
                            >
                              {{ item.text }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in aDisputes"
                            :key="index"
                          >
                            <td class="mon-regular text-left">{{ item.oSalesOrder.sFolioId }}</td>
                            <td class="mon-regular text-left">{{ item.oPurchaseOrder.sFolioId }}</td>
                            <td class="mon-regular text-left">{{ item.oVendor.sName }}</td>
                            <td class="text-center">
                              <v-btn
                                icon
                                @click="redirecDisputeDetail(item.sClaimTicketId)"
                              >
                                <v-icon
                                  color="#000"
                                  size="16px"
                                >
                                  mdi-eye
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                    <div class="pagination-styles">
                      <v-pagination
                        v-model="iCurrentPage"
                        :length="lengthPage"
                        color="#FFC556"
                        next-icon="mdi-menu-right"
                        prev-icon="mdi-menu-left"
                      ></v-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="card-content"
                style="height: 450px; display: flex; align-items: center; justify-content: center;"
              >
                <p
                  class="mon-regular"
                  style="text-align: center; font-size: 20px; color: #c1c1c3;"
                >{{ texts.disputes.textEmpty }}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisputesContentTabTwoLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      iCurrentPage: 1,
      lengthPage: 0,
      aDisputes: [],
      loaderSkeleton: true,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getDisputes();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    redirecDisputeDetail: function (id) {
      this.$router.push({
        name: "DisputeDetail",
        params: {
          id: id,
        },
      });
    },
    getDisputes: function () {
      this.loaderSkeleton = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/claim-tickets`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 12,
          iFilter: 2,
        },
      })
        .then((response) => {
          this.loaderSkeleton = false;
          this.aDisputes = response.data.results;

          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getDisputes();
      }
    },
    refresh: function () {
      this.getDisputes();
    },
    iCurrentPage: function () {
      this.getDisputes();
    },
  },
};
</script>

<style scoped>
.dispute-status-text {
  color: red !important;
  font-weight: bold;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.button-add-buyer {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  height: 50px !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.text-link {
  color: #0971fb;
  text-decoration: underline;
}

.text-link:hover {
  cursor: pointer;
}

.text-header-title {
  color: #000000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #949494 !important;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.display-flex {
  display: flex;
}

.align-items {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.content-tab {
  padding: 30px 0px;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom-line {
  border-bottom: 1px solid #cecece;
}
</style>